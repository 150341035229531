<template>
  <div class="container item-price">
    <div class="row">
      <div class="col-12 list">
        <div class="list-p">
          <b>{{ $t("lesson.item.basic_price") }} </b>
          <b>{{ formatCurrency(priceLesson.currency_code, priceLesson.price) }} </b>
        </div>
      </div>
      <div class="col-12 list mt-3" v-if="priceLesson.items.length">
        <div class="item" v-for="(item, index) in priceLesson.items" :key="index">
          <div class="list-p">
            <div>
              <input type="checkbox" :id="`item-option-${index}`" @change="(e) => handlePrice(e, item)" disabled />
              <label class="item-name" :for="`item-option-${index}`"> {{ item.name }} </label>
            </div>
            <b>{{ formatCurrency(priceLesson.currency_code, item.price) }} </b>
          </div>
        </div>
      </div>
      <div class="col-12 list mt-3 purchase">
        <h4 class="price">{{ formatCurrency(priceLesson.currency_code, priceLesson.price) }} </h4>
        <button class="text-center button-purchase" >
          {{ $t("lesson.detail.reservation_screen") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
  },
  computed: {
    priceLesson() {
      return this.data.price_lesson;
    },
  },
}
</script>

<style></style>